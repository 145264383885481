* {
  margin: 0;
  padding: 0;
}

.theme-container {
  background-color: var(--bg-color);
  color: var(--font-color);
  font-family: var(--text-font);
}

.sr-only {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  white-space: nowrap;
}

html {
  height: 100%;
}

body {
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: var(--font-size);
  line-height: var(--line-height);
  background-color: var(--bg-color);
  color: var(--font-color);
}

.grow {
  flex-grow: 1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-sansserif);
}

h1 {
  font-size: calc(var(--font-size) * 1.7);
}

h2 {
  font-size: calc(var(--font-size) * 1.3);
}

a {
  color: var(--font-color);
  text-decoration: none;
}

.a-nude {
  background-size: 0% 100%;
  transition: background-size 0.5s 0s;
  &:hover {
    background: var(--lines);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}

p, li {
  font-size: var(--font-size);
  margin-bottom: var(--line-height);
}

hr {
  margin-bottom: var(--line-height);
  border: 3px solid var(--custom-color);
}

tr {
  line-height: calc(var(--line-height)/3);
}

blockquote {
  margin-right: 25px;
  margin-left: 25px;
  margin-bottom: var(--line-height);
  border-width: 2px;
  background: var(--quote-color);
  padding: 10px 10px 1px 20px;
  border-left-width: 5px;
  border-left-style: solid;
  border-left-color: var(--custom-color);
  quotes: "“" "”" "‘" "’";
}

q {
  quotes: "‘" "’" "“" "”";
}

code{
  background: var(--quote-color);
}

.footnotes::before {
  content: "---";
}

.footnotes li {
  counter-increment: my-counter;
  margin-bottom: var(--line-height);
}

.footnotes li::before {
  content: counter(my-counter) ". ";
  font-weight: bold;
}

.footnotes p {
  display: inline;
}

label {
  cursor: pointer;
  font-weight: bold;
  font-size: var(--font-size);
  background: var(--lines);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  transition: background-size 0.5s 0s;
  margin-right: 20px;
  padding-bottom: 2px;
}

label:hover {
  background-size: 0% 100%;
}

/** HEADER **/
header {
  font-family: var(--font-sansserif);
  text-transform: uppercase;
  margin: 30px;
  line-height: calc(var(--line-height) / 1.5);
}

.site_title {
  text-transform: uppercase;
  display: inline-block;
  font-size: calc(var(--font-size) * 2.5);
  padding-bottom: 5px;
  font-weight: bold;
  background: none;
}

.pages_links {
  display: inline-block;
  font-size: calc(var(--font-size) * 1.5);
  padding-right: 20px;
  font-weight: bold;
}

.pages_names {
  background: var(--lines);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  transition: background-size 0.5s 0s;
  padding-bottom: 5px;
  &:hover {
    background-size: 0% 100%;
  }
}

.site_functions {
  margin-top: 10px;
}

/** MAIN **/
main {
  padding: 30px;
  min-height: 55%;
  a {
    background: var(--lines);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    transition: background-size 0.5s 0s;
    &:hover {
      background-size: 0% 100%;
    }
  }
}

.line {
  height: 30px;
  border-bottom: 10px solid var(--custom-color);
  transform: rotate(2deg);
  position: relative;
  top: var(--line-height);
  margin-bottom: calc(var(--line-height) * 3);
}

/** HOME **/
.list_post_title,
.list_post_info {
  font-weight: normal;
}

.list_post_info {
  font-size: 22px;
}

/** PAGES **/
article {
  max-width: var(--text-width);
  margin: auto;
  margin-top: calc(var(--line-height) * 3);
  margin-bottom: calc(var(--line-height) * 3);

}

article h1,h2,h3,h4,h5,h6{
  margin-bottom: var(--line-height);
}

.page-typo-type {
  margin-bottom: var(--line-height);
  text-align: right;
}

.post_footer {
  text-align:right;
}

/** CUSTOM-PAGES (archives & authors) **/
.custom-pages-title {
  margin-bottom: calc(var(--line-height) * 2);
}

.archives-table {
  width: 100%;
}

.archives-date {
  text-align: right;
}

.authors-list {
  text-align: center;
}

.author-archives {
  margin-top: var(--line-height);
}


/** FOOTER **/
footer {
  font-size: calc(var(--font-size) / 2);
  text-align: center;
  width: 100%;
  background: var(--bg-color);
  font-family: var(--font-sansserif);
}


/* Variables for responsive layouts */
@media screen and (max-width: 801px)
{
  :root{
    line-height: calc(var(--font-size)*1.5);
  }

  .site_title{
    margin-bottom:calc(var(--line-height)*0.5);
  }

  .site_title, .list_post_title{
    font-size:calc(var(--font-size)*1.5);
    }

  .pages_links{
    font-size:var(--font-size);
  }

  label{
    font-size: calc(var(--font-size)*0.8);
  }

  .typo-plus-label{
    display:none;
  }

  article{
    margin-top: var(--line-height);
    margin-bottom: var(--line-height);
  }

  .list_post_info{
    margin-bottom: 0;
  }

}

@media screen and (max-width: 600px) {
  tr {
    line-height: var(--line-height);
  }

  .archives-date{
    display:none;
  }

  .authors-list{
    text-align:left;
  }

  .desktop-star{
    display:none;
  }
}