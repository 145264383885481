@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Montserrat:ital,wght@0,400;0,700;1,400;1,700&display=swap");

:root {
  // Variable for the light mode
  --bg-color: #f2f2f2; // Background color
  --font-serif: "Libre Baskerville", serif; // Serif font
  --font-sansserif: "Montserrat", sans-serif; // Sans-Serif font
  --font-color: #333333; // Font color
  --font-size: 22px; // Font size
  --text-width: 35em; // Width of the area where the content of the pages and posts are displayed
  --text-font: var(--font-serif); // Default font for the text
  --line-height: calc(var(--font-size) * 2); // Line height
  --custom-color: cyan; // Complementary color, for the lines and underlinings
  --lines: linear-gradient(
    to top,
    var(--custom-color) 50%,
    rgba(255, 255, 255, 0) 50%
  ); // Lines
  --quote-color: #e7e8ea; // Background-color for quotes
  --dark-mode-label: "DARK MODE"; // Label displayed in the header to toggle dark mode
  --light-mode-label: "LIGHT MODE"; // Label displayed in the header to toggle light mode
  --typo-plus-label: "A+"; // Label displayed in the header to enlarge the font size
  --typo-moins-label: "A-"; // Label displayed in the header to reduce the font size
  --sans-serif-label: " SANS-SERIF FONT"; // Label displayed at the top of posts to change the font-style to sans-serif
  --serif-label: "SERIF FONT"; // Label displayed at the top of posts to change the font-style to serif
}

/* Dark-mode styles */
#dark-mode:checked,
#dark-mode:checked ~ .theme-container {
  --bg-color: #333; // Background color
  --font-color: #eee; // Font color
  --custom-color: #ef1a63; // Complementary color, for the lines and underlinings
  --lines: linear-gradient(
    to top,
    var(--custom-color) 50%,
    rgba(255, 255, 255, 0) 50%
  ); // Lines
  --quote-color: #333642; // Background-color for quotes
}

.dark-mode-label::before {
  content: var(
    --dark-mode-label
  ); // If you want to change the displayed text to change mode, change it in the :root section of this file
}

.dark-mode-checkbox:checked ~ .theme-container .dark-mode-label::before {
  content: var(
    --light-mode-label
  ); // If you want to change the displayed text to change mode, change it in the :root section of this file
}

/* Large font mode styles */
#typo-plus:checked,
#typo-plus:checked ~ .theme-container {
  --font-size: 30px; // Font-size when large fonts are toggled
  --line-height: 35px; // Line height when large fonts are toggled
  --text-width: 60em; // Width of the area where the content of the pages and posts are displayed
  .site_title {
    font-size: calc(var(--font-size) * 3);
    line-height: calc(var(--line-height) * 3);
  }
  .site_functions {
    margin-top: 20px;
  }
}

.typo-plus-label::before {
  content: var(
    --typo-plus-label
  ); // If you want to change the displayed text to change mode, change it in the :root section of this file
}

.typo-plus-checkbox:checked ~ .theme-container .typo-plus-label::before {
  content: var(
    --typo-moins-label
  ); // If you want to change the displayed text to change mode, change it in the :root section of this file
}

/* Typo-dys */
.typo-dys-label::before {
  content: var(
    --sans-serif-label
  ); // If you want to change the displayed text to change mode, change it in the :root section of this file
  font-size: calc(
    var(--font-size) / 1.5
  ); // Size font of the displayed text to switch between serif and sans-serif
  font-family: var(--font-sansserif);
}

.typo-dys-checkbox:checked ~ .theme-container .typo-dys-label::before {
  content: var(
    --serif-label
  ); // If you want to change the displayed text to change mode, change it in the :root section of this file
  font-family: var(--font-serif);
}

#typo-dys:checked,
#typo-dys:checked ~ .theme-container {
  --text-font: var(--font-sansserif);
}

